// @ts-nocheck
import { UPDATE_PUSH_STATUS } from './push.action';

export default (
    state = {
        supported: undefined,
        permission: undefined,
        topics: [],
        topicsActive: [],
        inProgress: false,
        error: undefined,
    },
    { type, payload },
) => {
    switch (type) {
        case UPDATE_PUSH_STATUS:
            return { ...state, ...payload };
        default:
            return state;
    }
};
