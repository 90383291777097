// @ts-nocheck
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import guid from '@whiz-cart/node-shared/guid';
import memoize from '@whiz-cart/node-shared/memoize';
import equals from 'fast-deep-equal/es6/react';
import { MouseEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import store from '../store';
import { addAction, removeAction } from './titleBar.action';

export type ActionConfig = {
    index?: number;
    label?: React.ReactNode;
    id?: string;
    display?: boolean;
    icon?: ReactElement | IconProp;
    titleBarLinkId?: string;
    action: string | MouseEventHandler<HTMLDivElement>;
    className?: string;
    isLoading?: boolean;
};

export default (config) => (target, key, descriptor) => {
    const configFn = config instanceof Function ? config : () => config;

    const { componentDidMount, componentDidUpdate, componentWillUnmount } = target;
    const id = `${target.constructor.name}/${key}`;

    const update = (self) => {
        dispatch(self, configFn(self.props, self.state));
    };
    const dispatch = memoize((self, config) => {
        if (!('display' in config) || config.display) store.dispatch(addAction({ ...config, action: descriptor.value.bind(self), id }));
        else store.dispatch(removeAction(id));
    });

    target.componentDidMount = function () {
        componentDidMount?.bind(this)();
        update(this);
    };

    target.componentDidUpdate = function (...args) {
        componentDidUpdate?.bind(this)(...args);
        update(this);
    };

    target.componentWillUnmount = function () {
        componentWillUnmount?.bind(this)();
        store.dispatch(removeAction(id));
    };

    for (const action of store.getState().titleBar.actions) {
        const [c, f] = action.id.split('/');
        if (c === target.constructor.name && !target[f]) store.dispatch(removeAction(action.id));
    }
};

export function useAction(
    config: Omit<ActionConfig, 'action'>,
    action: ActionConfig['action'] = () => undefined,
    deps: React.DependencyList = [action],
) {
    const [id] = useState(guid());
    const configRef = useRef<ActionConfig>();

    if (!equals(configRef.current, config)) configRef.current = config;

    useEffect(() => {
        if (!('display' in config) || config.display) {
            store.dispatch(addAction({ ...config, action, id }));
        } else store.dispatch(removeAction(id));

        return () => {
            store.dispatch(removeAction(id));
        };
    }, [configRef.current, ...deps]);

    return action;
}
