// @ts-nocheck
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { urlReducer } from '@whiz-cart/ui-shared/url/url.reducer';
import { syncReducer } from '@whiz-cart/ui-shared/sync/sync.reducer';
import { createStore } from '@whiz-cart/ui-shared/store/createStore';
import navigation from './navigation/navigation.reducer';
import customer from './sessionMonitor/customer/customer.reducer';
import products from './product/product.reducer';
import productBrowser from './productBrowser/productBrowser.reducer';
import demoSessions from './demoSessions/demoSessions.reducer';
import titleBar from './titleBar/titleBar.reducer';
import sessionMonitor from './sessionMonitor/sessionMonitor.reducer';
import sessionMonitorDetails from './sessionMonitor/sessionMonitorDetails.reducer';
import noBarcode from './noBarcode/noBarcode.reducer';
import configSetting from './configSettings/configSettings.reducer';
import stores from './store/store.reducer';
import checkout from './checkout/checkout.reducer';
import config from './config/config.reducer';
import push from './push/push.reducer';
import cartLogin from './cartLogin/cartLogin.reducer';
import shelf from './mapEditor/shelfTraining/shelfTraining.reducer';
import CPromise from '@whiz-cart/node-shared/cPromise';
import { UrlState } from '@whiz-cart/ui-shared/url/url.action';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type Action = { type: string };
export type ThunkAction<TReturn = void> = BaseThunkAction<TReturn, RootState, never, Action>;

declare module 'react-redux' {
    interface DefaultRootState extends RootState {
        url: { params: { storeGuid?: string }; history: UrlState[]; path: string };
    }
}

const store = createStore({
    url: urlReducer,
    navigation,
    products: persistReducer({ key: 'products', storage }, products),
    productBrowser,
    titleBar,
    mhd: persistReducer({ key: 'mhd', storage }, syncReducer('mhd')),
    demoSession: persistReducer({ key: 'isDemoModeOn', storage }, demoSessions),
    customer,
    sessionMonitor,
    sessionMonitorDetails,
    noBarcode,
    configSetting,
    stores: persistReducer({ key: 'stores_v2', storage }, stores),
    checkout: persistReducer({ key: 'checkout', storage, whitelist: ['cashierGuid'] }, checkout),
    config,
    push: persistReducer({ key: 'push', storage, whitelist: ['topics', 'topicsActive'] }, push),
    cartLogin: persistReducer({ key: 'cartLogin', storage }, cartLogin),
    shelf: persistReducer({ key: 'shelvesToRestore', storage }, shelf),
});
export const persistor = persistStore(store, null, () => storeRehydration.resolve());
export const storeRehydration = new CPromise();

export default store;
