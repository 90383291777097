// @ts-nocheck
import Queue from '@whiz-cart/node-shared/queue';
import sleep from '@whiz-cart/node-shared/sleep';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import localforage from 'localforage';
import _ from 'lodash';
import { authService } from '../auth/auth.service';
import { getActiveRoles, hasAutoSubscribe } from '../auth/authHelpers';
import { serviceWorkerRegistration } from '../registerWorkers';
import store, { storeRehydration } from '../store';
import endpoint from '../util/endpoint';
import firebase from '../util/firebase';
import { updatePushStatus } from './push.action';

export const expandTopics = {
    sessionMonitor: {
        service: ['sessionMonitor_service'],
        detective: ['sessionMonitor_detective'],
        storeManager: ['sessionMonitor_service', 'sessionMonitor_detective'],
        rollout: ['sessionMonitor_service', 'sessionMonitor_detective'],
        admin: ['sessionMonitor_admin', 'sessionMonitor_service', 'sessionMonitor_detective'],
    },
};

const db = localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'firebase-messaging-database',
    storeName: 'firebase-messaging-store',
});

export const pushService = new (class PushService {
    queue = new Queue();
    isInitialized = false;

    reportError(msg, userMsg, e) {
        console.error(msg, e);

        store.dispatch(updatePushStatus({ error: userMsg || msg }));
    }

    async deleteToken() {
        console.debug('Hard reset push notifications');
        const messaging = (await firebase).messaging();

        try {
            await messaging.deleteToken();
            console.debug(`Deleted token on Firebase's end`);
        } catch (e) {
            console.error(`Failed to delete token on Firebase's end`, e);
        }

        try {
            const keys = await db.keys();
            for (const key of keys) await db.removeItem(key);
            console.debug('Deleted token from indexedDB', { keys });
        } catch (e) {
            console.error('Failed to delete token from indexedDB', e);
        }
    }

    /** Delete and reestablish all push subscriptions */
    updateSubscriptions = () => {
        this.queue.clear(null);
        this.queue.schedule(async () => {
            try {
                store.dispatch(updatePushStatus({ error: undefined, inProgress: true }));
                const messaging = (await firebase).messaging();
                await storeRehydration;

                const { url, push } = store.getState();
                const storeGuid = url.params?.storeGuid;
                const loginState = authService.loginState.getState();
                const roles = getActiveRoles(loginState)('store', storeGuid);
                let topics = calcTopics(push.topics, [...roles]);

                if (!loginState.isLoggedIn) return;

                const { publicVapidKey } = store.getState().config.firebase;
                let { topicsActive = [] } = store.getState().push;
                if (!storeGuid) topics = [];
                const obsoleteTopics = topicsActive.filter((a) => !topics.includes(a.topic) || a.storeGuid !== storeGuid);

                await Promise.all(
                    obsoleteTopics.map((entry) =>
                        endpoint('storeManager.unsubscribe', entry)
                            .delete()
                            .then(() => {
                                topicsActive = topicsActive.filter((x) => x !== entry);
                            })
                            .catch(() => {
                                // ignore
                            }),
                    ),
                );

                store.dispatch(updatePushStatus({ topicsActive }));

                if (obsoleteTopics.length > 0) {
                    await this.deleteToken();
                }

                if (topics.length > 0) {
                    let token;

                    for (let tries = 0; ; tries++) {
                        try {
                            do {
                                token = await messaging.getToken({
                                    vapidKey: publicVapidKey,
                                    serviceWorkerRegistration: await serviceWorkerRegistration,
                                });
                            } while (!token);
                            console.debug('Token:', token);
                            break;
                        } catch (e) {
                            if (e.code === 'messaging/permission-blocked') {
                                return this.reportError(
                                    'Unable to get permission to notify.',
                                    'Berechtigung für PushService nicht erteilt',
                                    e,
                                );
                            }
                            if (tries >= 10) {
                                return this.reportError(
                                    'An error occurred while retrieving token.',
                                    'Einrichten des PushService fehlgeschlagen',
                                    e,
                                );
                            }
                            await sleep(30000);
                        }
                    }

                    await Promise.all(
                        topics.map((topic) =>
                            endpoint('pushSubscribe', { storeGuid })
                                .post({ topic, token })
                                .then(() => {
                                    if (!topicsActive.some((a) => a.topic === topic && a.storeGuid === storeGuid)) {
                                        topicsActive = [...topicsActive, { topic, storeGuid }];
                                    }
                                })
                                .catch((e) => {
                                    this.reportError(
                                        'Failed to send notification to backend.',
                                        'Einrichten der Benachrichtigungen im Backend fehlgeschlagen',
                                        e,
                                    );
                                }),
                        ),
                    );
                }

                store.dispatch(updatePushStatus({ topicsActive }));
                console.debug('Push subscriptions:', storeGuid, topicsActive);
            } catch (e) {
                this.reportError('Unexpected error', undefined, e);
            } finally {
                store.dispatch(updatePushStatus({ inProgress: false }));
            }
        });
    };

    async unsubscribeAll() {
        const { topicsActive = [] } = store.getState().push;

        await Promise.all(
            topicsActive.map(async (entry) => {
                try {
                    await endpoint('storeManager.unsubscribe', entry).delete();
                } catch (e) {
                    // ignore
                }
            }),
        );

        store.dispatch(updatePushStatus({ topics: [], topicsActive: [] }));
    }

    async toggle(topic, force) {
        let topics = store.getState().push.topics ?? [];
        if (force !== true && (force === false || topics.includes(topic))) topics = topics.filter((x) => x !== topic);
        else topics = _.uniq([...topics, topic]);
        store.dispatch(updatePushStatus({ topics }));
        this.updateSubscriptions();
    }

    async sendMessage(
        topic = 'sessionMonitor_service',
        payload = {
            type: 'ticket',
            title: 'Cart 901 - SOS',
            body: `${new Date().toLocaleTimeString()} Preisabweichung`,
            tag: '1234567890',
            renotify: true,
            data: '/customer/901',
        },
    ) {
        const { storeGuid } = await store.awaitState('url.params');
        endpoint('pushSend', { storeGuid, topic }).post(payload);
    }
})();
window.pushService = pushService;

const calcTopics = (topics, roles) => {
    return _.flatMap(topics, (topic) => {
        const expand = expandTopics[topic];
        if (!expand) return [topic];
        return _(roles)
            .flatMap((role) => expand[role] ?? [])
            .uniq()
            .value();
    });
};

export const managePushSubscriptions = () => [
    store.subscribeState(
        ({ url, push }) => ({
            storeGuid: url.params?.storeGuid,
            permission: push.permission,
        }),
        async () => {
            pushService.updateSubscriptions();
        },
    ),

    authService.loginState.subscribe(
        (loginState) => loginState.isLoggedIn,
        async () => {
            pushService.updateSubscriptions();
        },
    ),

    authService.loginState.subscribe(hasAutoSubscribe, (autoSubscribe) => {
        if (!autoSubscribe) return;
        const topics = store.getState().push.topics ?? [];
        if (topics.includes('sessionMonitor')) return;
        store.dispatch(updatePushStatus({ topics: [...topics, 'sessionMonitor'] }));
    }),

    (() => {
        const messageListener = ({ data: { navigate } }) => {
            console.debug('Received message from service worker to navigate to:', navigate);
            urlService.pushUrl(navigate);
        };
        navigator?.serviceWorker?.addEventListener('message', messageListener);
        return () => navigator?.serviceWorker?.removeEventListener('message', messageListener);
    })(),

    (() => {
        let cancel;

        (async () => {
            let stopped = false;
            cancel = () => (stopped = true);

            const navigatorPermissions = await navigator.permissions?.query({ name: 'notifications' });
            if (stopped) return;

            const updatePermission = async () => {
                const permission = navigatorPermissions?.state;

                store.dispatch(
                    updatePushStatus({
                        supported: !!window.navigator.serviceWorker,
                        permission,
                    }),
                );
            };

            updatePermission();
            navigatorPermissions?.addEventListener('change', updatePermission);
            cancel = () => navigatorPermissions?.removeEventListener('change', updatePermission);
        })();

        return () => cancel?.();
    })(),
];
