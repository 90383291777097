// @ts-nocheck
import _ from 'lodash';
import store from '../store';
import { formatDeviceName } from '../util/deviceName';
import endpoint from '../util/endpoint';

export const SET_DEVICES_LOADING = 'SET_DEVICES_LOADING';
export const LOAD_DEVICES_COMPLETE = 'LOAD_DEVICES_COMPLETE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const CLOSE_SESSION = 'CLOSE_SESSION';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';
export const UPDATE_DEVICE_LOCATIONS = 'UPDATE_DEVICE_LOCATIONS';
export const CLEAN_LOCATIONS = 'CLEAN_LOCATIONS';
export const UPDATE_WEIGHTS = 'UPDATE_WEIGHTS';

const transformDevice = ({ deviceId, targetId, deviceMetadata, ...device }) =>
    _.omit(
        {
            ...device,
            deviceId: formatDeviceName(deviceId || targetId, device.isMobile),
            deviceMetadata: deviceMetadata ?? (device.isMobile ? { hasScale: 'false' } : undefined),
        },
        ['isSessionOpen', 'hasTicket'],
    );

export const setDevicesLoading = (loading) => ({ type: SET_DEVICES_LOADING, payload: loading });

export const fetchSessions = (storeGuid: string) => (dispatch: any) => {
    return endpoint('storeManager.getActiveDevices', { storeGuid })
        .get()
        .then((devices) => dispatch({ type: LOAD_DEVICES_COMPLETE, payload: devices.map(transformDevice) }));
};

export const updateDevice = (device) => async (dispatch: any) => {
    dispatch({
        type: UPDATE_DEVICE,
        payload: transformDevice(device),
    });
};

export const closeSession = (session) => ({
    type: CLOSE_SESSION,
    payload: session,
});

export const updateTicket = (ticket) => ({
    type: UPDATE_TICKET,
    payload: transformDevice(ticket),
});

export const removeTicket = (ticketId: string) => ({
    type: removeTicket,
    payload: ticketId,
});

export const updateWeights = (msg) => ({
    type: UPDATE_WEIGHTS,
    payload: msg,
});

export const updateDeviceLocations = (msg) => ({
    type: UPDATE_DEVICE_LOCATIONS,
    payload: msg.map(transformDevice),
});

export const closeTickets = (ticketId: string) => async (dispatch: any) => {
    const storeGuid = (await store.awaitState('url.params.storeGuid')) as string;
    const response = await endpoint('storeManager.closeTickets', { storeGuid }).post({ ticketId });
    if (!response) {
        throw Error('Failed to close ticket');
    }
    dispatch(removeTicket(ticketId));
};

export async function assignTicket(
    storeGuid: string,
    { userName, deviceId, ticketIds }: { userName?: string; deviceId: string; ticketIds: string[] },
) {
    return await endpoint('storeManager.assignTickets', { storeGuid }).post({ userName, deviceId, ticketIds });
}

export async function unassignTicket(storeGuid: string, { ticketIds }: { ticketIds: string[] }) {
    return await endpoint('storeManager.unassignTickets', { storeGuid }).post({ ticketIds });
}

export const cleanLocations = () => ({ type: CLEAN_LOCATIONS });
