import { z } from 'zod';

export const Tenant = z.array(
    z.object({
        id: z.string(),
        name: z.string(),
        tenants: z.string().array().nullish(),
        type: z.string(),
        value: z.string().nullish(),
    }),
);
export type Tenant = z.infer<typeof Tenant>;
