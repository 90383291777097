import { configureTranslations } from '@whiz-cart/ui-shared/translate';
import orderBy from 'lodash/orderBy';
import { createTranslator, FlatKeys, FlattenDict } from 'schummar-translate/react';
import en from './translations/languages/en';
import meta from './translations/languages/generated/meta.json';

export type TranslationKeys = FlatKeys<typeof en>;

const loadDict = (loader: () => Promise<any>) => () => loader().then((mod) => mod.default);

const dicts: Record<string, () => Promise<Dict>> = {
    de: loadDict(() => import('./translations/languages/generated/de')),
    'fr-CA': loadDict(() => import('./translations/languages/generated/fr-CA')),
    fr: loadDict(() => import('./translations/languages/generated/fr')),
    nb: loadDict(() => import('./translations/languages/generated/nb')),
};

const warningsSeen = new Set<string>();

export type Dict = FlattenDict<typeof en>;

export const { t, useTranslator, getTranslator, clearDicts } = createTranslator({
    sourceLocale: 'en',
    sourceDictionary: en,
    dicts,
    fallbackLocale: ['de', 'en'],
    ignoreMissingArgs: true,

    warn: (locale, id) => {
        const key = `${locale}:${id}`;
        if (warningsSeen.has(key)) return;
        warningsSeen.add(key);
        console.warn('Missing translation:', locale, key);
    },

    dateTimeFormatOptions: {
        dateStyle: 'medium',
        timeStyle: 'medium',
    },
});
configureTranslations({ t, useTranslator, getTranslator, clearDicts });

const AdditionalProperties: { [code: string]: { region?: string } } = {
    de: { region: 'DE' },
    en: { region: 'GB' },
    'en-CA': {},
    'fr-CA': {},
    fr: { region: 'FR' },
    nb: { region: 'NO' },
};

export const LanguageMeta = {
    ...meta,
    languages: orderBy(
        meta.languages.map((language) => {
            const code = [language.language, language.region].filter(Boolean).join('-');
            return {
                code,
                ...language,
                ...AdditionalProperties[code],
            };
        }),
        (language) => {
            const index = Object.keys(AdditionalProperties).indexOf(language.code);
            return index >= 0 ? index : Infinity;
        },
    ),
};

export const browserLocale = dicts[navigator.language] ? navigator.language : navigator.language.slice(0, 2);
