import { ParsedBoolean, ParsedFalse } from '@whiz-cart/node-shared/models/parsed/parsedBoolean';
import { z } from 'zod';
import configBase from './envs/config.base';

export type ConfigBase = typeof configBase;

export const ConfigEnv = z.object({
    env: z.object({
        id: z.string(),
        name: z.string(),
        tier: z.string(),
        allowEnv: z.preprocess((s) => (typeof s === 'string' ? s.split(',') : s), z.string().array()),
        allowVersion: z.string(),
    }),
    backends: z.object({
        storeManager: z.string(),
        cashier: z.string(),
        devOps: z.string(),
        globaldeployment: z.string(),
        deployment: z.string(),
        config: z.string(),
        azureMonitoring: z.string(),
        mobileBackend: z.string(),
        search: z.string(),
        product: z.string(),
        storeAdmin: z.string(),
        dsi: z.string(),
        dataSync: z.string(),
        buildMonitor: z.string(),
        navigationService: z.string(),
        masterDataService: z.string(),
    }),
    firebase: z.object({
        apiKey: z.string(),
        authDomain: z.string(),
        databaseURL: z.string(),
        projectId: z.string(),
        storageBucket: z.string(),
        messagingSenderId: z.string(),
        appId: z.string(),
        publicVapidKey: z.string(),
    }),
    dsi: z.object({
        numberOfCachedEventsImages: z.number().optional(),
    }),
    showEnvColor: z.string().or(ParsedFalse),
    cdnUrl: z.string(),
    cdnToken: z.string(),
    brandImageUrl: z.string(),
    snapshotImageUrl: z.string(),
    supportInfoUrl: z.string(),
    appInsightsInstrumentationKey: z.string().nullish(),
    feature: z
        .object({
            manualShelfNumber: ParsedBoolean,
            logAnalyzer: ParsedBoolean,
            reporting: ParsedBoolean,
        })
        .partial()
        .optional(),
    trainingMaterial: z.object({
        documentUrls: z.preprocess(
            (s) => (typeof s === 'string' ? s.split(',') : s),
            z.tuple([z.string(), z.string(), z.string(), z.string(), z.string(), z.string(), z.string()]),
        ),
    }),
    couponimagesURL: z.string(),
    localazyCdn: z.string(),
    tracing: z
        .discriminatedUnion('type', [
            z.object({
                type: z.literal('appinsights'),
                key: z.string().nullish(),
            }),
            z.object({
                type: z.literal('grafana'),
                collector: z.string(),
            }),
        ])
        .nullish(),
});

export type ConfigEnv = z.infer<typeof ConfigEnv>;
