import { Store } from '@whiz-cart/node-shared/models/store';
import store from '../store';
import { ScopeType } from '../user/user.service';
import { authService } from './auth.service';
import { LoginState, ScopeParameters } from './authTypes';
import { calcActiveRoles } from './calcActiveRoles';
import { calcHasClaim } from './calcHasClaim';

export const getActiveRoles =
    (loginState?: LoginState | null, stores?: Record<string, Store>, activeStoreGuid?: string) =>
    (...scope: ScopeParameters) => {
        loginState ??= authService.loginState.getState();
        stores ??= store.getState().stores.stores as Record<string, Store>;
        activeStoreGuid ??= store.getState().url.params.storeGuid;

        return calcActiveRoles(loginState, stores, activeStoreGuid)(...scope);
    };

export const hasClaim =
    (loginState?: LoginState | null, stores?: Record<string, Store>, activeStoreGuid?: string) =>
    (claims: string | string[], ...scope: ScopeParameters) => {
        loginState ??= authService.loginState.getState();
        stores ??= store.getState().stores.stores as Record<string, Store>;
        activeStoreGuid ??= store.getState().url.params.storeGuid;

        return calcHasClaim(loginState, stores, activeStoreGuid)(claims, ...scope);
    };

export function hasAutoSubscribe(loginState = authService.loginState.getState()) {
    return (
        loginState.tokenDetails?.roles.some(
            ({ roles }) => roles.includes('service') || roles.includes('detective') || roles.includes('maintenance'),
        ) ?? false
    );
}

export function hasSingleStore(loginState = authService.loginState.getState()) {
    const roles = loginState.tokenDetails?.roles || [];
    return roles.length === 1 && roles[0]?.scope === 'store' ? roles[0].path.store : false;
}

export function hasScope(scope: ScopeType, loginState = authService.loginState.getState()) {
    const roles = loginState.tokenDetails?.roles || [];
    const scopeRole = roles.find((scopeRole) => scopeRole.scope === scope || scopeRole.scope === 'global');
    return (scopeRole?.roles?.length ?? 0) > 0;
}

export function calcAssignableRoles(roleHierarchy: Record<string, string[]>) {
    return (...assignerRoles: string[]) => {
        const traverse = (role: string): string[] => {
            const children = roleHierarchy[role] ?? [];
            return [role].concat(children.flatMap(traverse));
        };

        const assignableRoles = assignerRoles.flatMap(traverse);
        return new Set(assignableRoles);
    };
}

const authDebug = {
    getActiveRoles: getActiveRoles(),
    hasClaim: hasClaim(),
    hasAutoSubscribe,
    hasSingleStore,
    hasScope,
    calcAssignableRoles: (...assignerRoles: string[]) =>
        calcAssignableRoles(authService.roleHierarchy.getCacheValue(undefined) ?? {})(...assignerRoles),
};

Object.assign(window, { authDebug });
