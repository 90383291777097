import { brandEditorService } from '@brandEditor/brandEditor.service';
import BuildMonitorStandalone from '@buildMonitor/buildMonitorStandalone';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import storeService from '@store/store.service';
import { FlatProviders } from '@util/flatProviders';
import { BrandProvider } from '@whiz-cart/ui-shared/brand/brandContext';
import LoadingIndicator from '@whiz-cart/ui-shared/loading/loadingIndicator';
import { OverlayContainer } from '@whiz-cart/ui-shared/overlay/overlayContainer.component';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import c from 'classnames';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { LEFT, RIGHT, SwipeEventData } from 'react-swipeable';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { TableThemeProvider, DatePickerProvider } from 'schummar-table';
import { TranslationContextProvider } from 'schummar-translate/react';
import css from './app.module.less';
import { appState } from './appState';
import { Swipeable } from './components/swipeable';
import ErrorBoundary from './error/errorBoundary.component';
import { useConfigureLocales } from './hooks/useConfigureLocales';
import { useDateFnsLocale } from './hooks/useDateFnsLocale';
import ForgotPassword from './login/forgotPassword';
import Login from './login/login.component';
import Navigation from './navigation/navigation';
import { toggleNavigation } from './navigation/navigation.action';
import { useAccessibleRoutes, Route as RouteDefinition } from './routes';
import store, { persistor } from './store';
import { theme } from './theme';
import TitleBar from './titleBar/titleBar.component';
import { browserLocale, t } from './translate';
import UserActivation from './user/userActivation.component';
import { tableTheme } from './util/tableTheme';
import { DialogProvider } from '@util/imperativeDialog';
import { useRedirectToStore } from '@store/storeAutoSelect';

function Main() {
    const dispatch = useDispatch();
    const navigationToggled = useSelector((state) => state.navigation);
    const navigationHidden = useSelector((state) => state.titleBar?.current?.hideNavigation);
    const stores = useSelector((state: any) => state.stores.stores);
    const url = useSelector((state: any) => state?.url);
    const storeGuid = useSelector((state: any) => state.url?.params?.storeGuid);
    const { isLoggedIn, accessibleRoutes, isLoading: isLoadingRoutes } = useAccessibleRoutes();

    useConfigureLocales();

    function onSwiped({ dir, velocity, absX, event }: SwipeEventData) {
        // Open/hide navigation on swipe flick gesture
        const startX = ((event as TouchEvent).changedTouches?.[0]?.clientX ?? 0) - absX;
        if (startX < 50 && dir === RIGHT && velocity > 0.2 && !navigationToggled) {
            dispatch(toggleNavigation(true));
        } else if (dir === LEFT && velocity > 0.2 && navigationToggled) {
            dispatch(toggleNavigation(false));
        }
    }

    function renderRoute(route: RouteDefinition) {
        if (route.redirect) {
            return <Redirect key={route.path} exact={route.exact} path={route.path} to={urlService.calcUrl(route.redirect)} />;
        }

        return (
            <Route key={route.path} exact={route.exact} path={route.path}>
                {(props) => (
                    <>
                        {route.component && <route.component {...props} />}

                        {route.nestedChildren?.length ? <Switch>{route.nestedChildren.map(renderRoute)}</Switch> : null}
                    </>
                )}
            </Route>
        );
    }

    useRedirectToStore(stores, storeGuid, url);

    const defaultPage = accessibleRoutes.find((x) => x.translationKey);
    const defaultPath = defaultPage?.link;

    return (
        <Router history={urlService.history as any}>
            <DialogProvider global>
                <Switch>
                    <Route path="/activate">
                        <UserActivation />
                    </Route>

                    <Route path="/forgotPassword">
                        <ForgotPassword />
                    </Route>

                    <Route path="/standalone/buildMonitor">
                        <BuildMonitorStandalone />
                    </Route>

                    {isLoggedIn === false && (
                        <Route>
                            <Login />
                        </Route>
                    )}

                    {isLoggedIn === true && !isLoadingRoutes && (
                        <Route>
                            <Swipeable
                                testId="appMain"
                                className={c(css.main, { [css.navigationHidden]: !navigationToggled || navigationHidden })}
                                onSwiped={onSwiped}
                            >
                                {!isLoadingRoutes && <Navigation />}
                                <TitleBar />
                                <div data-testid="appContent" className={css.content}>
                                    <Suspense fallback={<LoadingIndicator label={t('loadingModule')} />}>
                                        <Switch>
                                            {accessibleRoutes.map(renderRoute)}
                                            {defaultPath && <Redirect to={urlService.calcUrl(defaultPath)} />}
                                        </Switch>
                                    </Suspense>
                                </div>
                            </Swipeable>
                        </Route>
                    )}
                </Switch>
            </DialogProvider>
        </Router>
    );
}

export default function App() {
    const locale = appState.useState('locale') ?? browserLocale;
    const dateAdapterLocale = useDateFnsLocale(locale);

    const storeGuid = urlService.state.useStore((url) => url.params.storeGuid);
    const [storeConfig] = storeService.getConfig.useAction(storeGuid ?? '', { dormant: !storeGuid });
    const [storeBrand] = brandEditorService.get.useAction(storeConfig?.branding ?? '', { dormant: !storeConfig?.branding });
    const overrideBrand = appState.useState('overrideBrand');
    const brand = overrideBrand ?? storeBrand;

    /* eslint-disable react/jsx-key */
    const providers = [
        <StyledEngineProvider injectFirst />,
        <ThemeProvider theme={theme} />,
        <Provider store={store} />,
        <BrandProvider brand={brand} />,
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateAdapterLocale} />,
        <TableThemeProvider theme={tableTheme} />,
        <DatePickerProvider showCalendarWeek locale={locale} />,
        <TranslationContextProvider locale={locale} />,
        <ErrorBoundary />,
        <SnackbarProvider maxSnack={4} hideIconVariant anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} preventDuplicate>
            {null}
        </SnackbarProvider>,
        <PersistGate loading={null} persistor={persistor} />,
    ];
    /* eslint-enable react/jsx-key */

    return (
        <FlatProviders providers={providers}>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
            />
            <Main />
            <OverlayContainer />
        </FlatProviders>
    );
}
