import { Store } from '@whiz-cart/node-shared/models/store';
import { RoleScope, RoleScopeType } from './authTypes';

export function resolveRoles(roles: RoleScope[] = [], stores: Record<string, Store>, scope: RoleScopeType, scopeId?: string) {
    const result = roles.filter((role) => isRoleApplicable(role, stores, scope, scopeId)).flatMap((entry) => entry.roles);
    return new Set(result);
}

export function isRoleApplicable(role: RoleScope, stores: Record<string, Store>, scope: RoleScopeType, scopeId?: string): boolean {
    // Global roles always apply
    if (role.scope === 'global') {
        return true;
    }

    // Scope is not global and no scopeId provided => no
    if (!scopeId) {
        return false;
    }

    // Role applies if its scope fits directly
    if (role.scope === scope) {
        const roleScopeId = role.path[role.scope as keyof typeof role.path];
        return roleScopeId === scopeId;
    }

    // Else roles apply if claims are requested for a store and the role's scope includes the store (e.g. store belongs to the tenant)
    if (role.scope !== 'store' && scope === 'store') {
        const roleScopeId = role.path[role.scope as keyof typeof role.path];

        const store = stores[scopeId];
        const storeScopeId = store && role.scope in store ? store[role.scope] : undefined;

        return !!storeScopeId && roleScopeId === storeScopeId;
    }

    return false;
}
