// @ts-nocheck
import PaymentStatus from '@whiz-cart/node-shared/paymentStatus';
import _ from 'lodash';
import {
    CANCEL_PAYMENT,
    CLEAR_IMAGES,
    COMPLETE_PAYMENT,
    LOCK_PAYMENT,
    LOCK_POS_PROCESSING_PAYMENT,
    SET_CASHIER_GUID,
    SET_PAYMENT_LOADING,
    SHOW_IMAGES,
    UNLOCK_PAYMENT,
    UNLOCK_POS_PROCESSING_PAYMENT,
    UPDATE_PAYMENT,
    UPDATE_PAYMENTS,
    VERIFY_PAYMENT,
} from './checkout.action';

// Payments will be remebered, if they are still active, or if they have been updated within the last hour (to show if the payment has been canceled for example).
const retention = 24 * 60 * 60 * 1000;
const markUpdatedOn = (payment) => ({ ...payment, updatedOn: new Date() });
const filterActive = (payment) => payment.status < PaymentStatus.Completed || payment.updatedOn >= new Date() - retention;

const updatePayment = (state, paymentRequestGuid, update) => ({
    ...state,
    payments: state.payments
        .map((payment) => (payment.paymentRequestGuid === paymentRequestGuid ? { ...payment, ...update } : payment))
        .filter(filterActive),
});

const updateVerificationItem = (state, paymentRequestGuid, verificationGuid, update) => ({
    ...state,
    payments: state.payments.map((payment) =>
        payment.paymentRequestGuid === paymentRequestGuid
            ? {
                  ...payment,
                  verificationItems: payment.verificationItems.map((item) =>
                      item.verificationItemGuid === verificationGuid ? { ...item, ...update } : item,
                  ),
              }
            : payment,
    ),
});

export default (
    state = {
        payments: [],
        hasLoadedOnce: false,
        loading: true,
        sessionAmount: [],
        expressCheckout: [],
    },
    { type, payload },
) => {
    switch (type) {
        case UPDATE_PAYMENTS:
            return { ...state, payments: payload.map(markUpdatedOn) };
        case UPDATE_PAYMENT:
            const payments = state.payments
                .filter((p) => p.paymentRequestGuid !== payload.paymentRequestGuid)
                .concat(markUpdatedOn(payload))
                .filter(filterActive);
            return { ...state, payments };
        case LOCK_PAYMENT:
            return updatePayment(state, payload.paymentRequestGuid, {
                processingBy: { userId: payload.userId, deviceId: payload.deviceId },
            });
        case UNLOCK_PAYMENT:
            return updatePayment(state, payload.paymentRequestGuid, { processingBy: null });
        case LOCK_POS_PROCESSING_PAYMENT:
            return updatePayment(state, payload.paymentRequestGuid, {
                postProcessingBy: { userId: payload.userId, deviceId: payload.deviceId },
            });
        case UNLOCK_POS_PROCESSING_PAYMENT:
            return updatePayment(state, payload.paymentRequestGuid, { postProcessingBy: null });
        case CANCEL_PAYMENT:
            return updatePayment(state, payload.paymentRequestGuid, { status: PaymentStatus.EmployeeCancelled });
        case COMPLETE_PAYMENT:
            return updatePayment(state, payload.paymentRequestGuid, {
                status: PaymentStatus.Completed,
                posRegistrationCompletedOn: payload.posRegistrationCompletedOn,
            });
        case VERIFY_PAYMENT:
            return updateVerificationItem(state, payload.paymentRequestGuid, payload.verificationGuid, { approved: payload.approved });
        case SET_PAYMENT_LOADING:
            return { ...state, hasLoadedOnce: state.hasLoadedOnce || !payload, loading: payload };
        case SHOW_IMAGES:
            const newScreenShot = { ...payload, date: new Date() };
            const expressCheckout = _.uniqBy(
                _.orderBy([...state.expressCheckout, newScreenShot], [(x) => x.date], ['desc']),
                (x) => x.cashierId,
            );
            return { ...state, expressCheckout };
        case CLEAR_IMAGES:
            return { ...state, expressCheckout: '' };
        case SET_CASHIER_GUID:
            return { ...state, cashierGuid: payload };

        default:
            return state;
    }
};
