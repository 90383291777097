// @ts-nocheck
import { Store, StoreStatus } from '@whiz-cart/node-shared/models/store';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { orderBy } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasSingleStore } from '../auth/authHelpers';
import { useTranslator } from '../translate';
import css from './navigation.module.less';
import { Autocomplete, Paper, TextField, TextFieldProps } from '@mui/material';
import c from 'classnames';
import { filterTruthy } from '@whiz-cart/node-shared/helpers/filterTruthy';
import useInputFocusShortcut from '@util/useInputFieldFocus';

export type StoreSelectAutocompleteProps = {
    stores: Record<string, Store>;
    onSelect: () => void;
};

export default function StoreSelectAutocomplete({ stores, onSelect }: StoreSelectAutocompleteProps) {
    const t = useTranslator();
    const [open, setOpen] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>();

    const storeGuid = useSelector((state: any) => state.url.params.storeGuid as string);
    const storeName = stores[storeGuid]?.name ?? storeGuid ?? t('global');
    const longStoreName = getStoreLabel(stores[storeGuid]) ?? storeGuid ?? t('global');

    function getStoreLabel(store: Store | undefined) {
        if (!store) return store;
        return store.name ? `${store.name} ${store.externalStoreId}` : store.externalStoreId;
    }

    const options = useMemo(
        () =>
            [
                !hasSingleStore() && {
                    value: undefined,
                    label: t('global'),
                    name: t('global'),
                    isDeEmphasized: false,
                    isEmphasized: storeGuid === undefined || false,
                },
                ...orderBy(stores, 'name').map((x) => ({
                    value: x.storeGuid,
                    label: getStoreLabel(x),
                    isDeEmphasized: !(x.status === StoreStatus.isActive),
                    isEmphasized: x.storeGuid === storeGuid,
                    name: x.name,
                })),
            ]
                .filter(filterTruthy)
                .sort((a, b) => (a.value === storeGuid ? -1 : b.value === storeGuid ? 1 : 0)),
        [stores, storeGuid],
    );

    function selectStore(storeGuid: string | undefined) {
        urlService.pushUrl({ storeGuid });
        onSelect();
    }

    const { modifierText } = useInputFocusShortcut('k', textFieldRef.current, { requireShift: true, customAction: focusTextField });

    function focusTextField() {
        setOpen(true);
        setTimeout(() => {
            textFieldRef.current?.focus();
        });
    }

    return open ? (
        <Autocomplete
            onClose={() => {
                setOpen(false);
                textFieldRef?.current && textFieldRef.current.blur();
            }}
            onOpen={() => {
                setOpen(true);
            }}
            blurOnSelect
            disableClearable
            ListboxComponent={Paper}
            PaperComponent={CustomPaper}
            isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
            }}
            autoHighlight
            openOnFocus
            options={options}
            value={null}
            getOptionLabel={(option) => {
                return option?.label ?? `${longStoreName}`;
            }}
            renderOption={(props, { isDeEmphasized, label, isEmphasized }) => {
                const style = {
                    fontWeight: isEmphasized ? 'bold' : 'normal',
                };
                return (
                    <li {...props} style={style}>
                        <div css={{ opacity: isDeEmphasized ? 0.4 : 1 }}>{label}</div>
                    </li>
                );
            }}
            onChange={(_event, { value }) => {
                selectStore(value);
            }}
            renderInput={(params) => {
                return (
                    <StyledTextField
                        {...params}
                        inputRef={textFieldRef}
                        InputProps={{ ...params.InputProps, endAdornment: <div className={css.modifierText}>{modifierText}</div> }}
                    />
                );
            }}
        />
    ) : (
        <StyledTextField
            className={c(css.storeSelector, css.close)}
            value={storeName}
            onFocus={() => {
                focusTextField();
            }}
            multiline
        />
    );
}

function StyledTextField(props: TextFieldProps) {
    const { InputProps, ...rest } = props;
    return (
        <TextField
            {...rest}
            InputProps={{
                ...InputProps,
                style: { padding: '5px 0px', border: 'none', color: 'inherit', fontWeight: '700' },
            }}
            sx={{ input: { color: 'white', fontSize: 'large', fontWeight: 'bold' } }}
        />
    );
}

function CustomPaper(props: any) {
    return <Paper {...props} sx={{ width: 'max-content', maxWidth: '90vw' }} />;
}
