import { Store } from '@whiz-cart/node-shared/models/store';
import { castArray } from 'lodash';
import { LoginState, ScopeParameters } from './authTypes';
import { isRoleApplicable } from './resolveRoles';

const claimWarnings = new Set<string>();

export function calcHasClaim(loginState: LoginState | undefined | null, stores: Record<string, Store>, activeStoreGuid?: string) {
    const roles = loginState?.tokenDetails?.roles ?? [];
    const claimMapping = loginState?.claimMapping;

    function hasClaim(claims: string | string[], ...[scope, scopeId]: ScopeParameters) {
        if (!Array.isArray(claims)) {
            claims = [claims];
        }

        if (process.env.NODE_ENV !== 'production') {
            for (const claim of claims) {
                if (claimMapping && !claimMapping.has(claim) && !claimWarnings.has(claim)) {
                    claimWarnings.add(claim);
                    console.warn('Using non existent claim:', claim);
                }
            }
        }

        if (!scope) {
            scope = activeStoreGuid ? 'store' : 'global';
            scopeId = activeStoreGuid;
        } else if (scope === 'store') {
            scopeId ??= activeStoreGuid;
        }

        const availableRoles = roles.filter((role) => isRoleApplicable(role, stores, scope!, scopeId)).flatMap((role) => role.roles);
        const availableNonStoreRoles = roles
            .filter((role) => role.scope !== 'store' && isRoleApplicable(role, stores, scope!, scopeId))
            .flatMap((role) => role.roles);

        return castArray(claims).every((claimId) => {
            const claim = claimMapping?.get(claimId);
            let rolesToCheck = claim?.allowAnyScope ? roles.flatMap((role) => role.roles) : availableRoles;

            if (claim?.mustBeEnabledForStore && scope === 'store' && scopeId) {
                const store = stores[scopeId];
                if (!store?.enabledClaims?.includes(claimId)) {
                    if (claim?.allowAnyScope) {
                        rolesToCheck = roles.filter((role) => role.scope !== 'store').flatMap((role) => role.roles);
                    } else {
                        rolesToCheck = availableNonStoreRoles;
                    }
                }
            }

            return rolesToCheck.some((role) => role === 'admin' || claim?.roles.has(role));
        });
    }

    return hasClaim;
}
