import { service } from '@whiz-cart/ui-shared/service/service';
import { Action } from 'schummar-state/react';
import endpoint from '../util/endpoint';
import { ProductListProps, ProductListResult, ProductOverview, StoreProduct } from './productBrowserTypes';
import { createCache } from 'cross-state';

class ProductBrowserService {
    transformIncomingHistory(history: any, type: any) {
        return {
            type,
            ...history,
        };
    }

    list = new Action(
        async ({
            query,
            skip = 0,
            limit = 10,
            storeGuid,
            filter,
            cgId,
            cgLongId,
            cacheKey = 'fallbackCacheKey',
            productLabel,
        }: ProductListProps) => {
            const useLabelEndpoint = filter === 'labelPriority';

            const { totalItemCount, items } = (await (useLabelEndpoint
                ? endpoint('storeManager.getProductListUnlabelled', { storeGuid, cacheKey })
                : endpoint('storeManager.getProductList', { storeGuid })
            ).get({
                params: {
                    skip,
                    limit,
                    itemId: query,
                    barcode: query,
                    active: filter === 'active' ? true : filter === 'inactive' ? false : undefined,
                    cgId,
                    cgIdLong: cgLongId,
                    productLabel,
                },
            })) as any;
            const parsedItems = ProductListResult.parse(items);

            return { totalItemCount, items: parsedItems };
        },
    );

    async setPositionTestShelf(shelf: { storeGuid: string; itemId: string; x: number; y: number; systemId: number; shelf: number }) {
        await endpoint('storeManager.setPositionTestShelf').post(shelf);
        await this.getProduct({ storeGuid: shelf.storeGuid, itemId: shelf.itemId }).get({ update: 'force' });
    }

    async removePositionTestShelf(shelf: { storeGuid: string; itemId: string; systemId: number; shelf: number }) {
        await endpoint('storeManager.removePositionTestShelf').delete(shelf);
        await this.getProduct({ storeGuid: shelf.storeGuid, itemId: shelf.itemId }).get({ update: 'force' });
    }

    async getNotFoundProducts(skip = 0, limit = 10, storeGuid: string) {
        const items = await endpoint('storeManager.navigationNotFoundProducts', { storeGuid }).get({
            params: {
                skip,
                limit,
            },
        });
        return items;
    }
    getProductByBarcode = (storeGuid: string, barcode: string) => {
        return endpoint('storeManager.getProductByBarcode', { storeGuid, barcode }).get();
    };

    getProduct = createCache(async ({ storeGuid, itemId }: { storeGuid: string; itemId: string }) => {
        try {
            const res = await endpoint('storeManager.getProductDetails', { storeGuid, itemId }).get();
            if (!res) return null;
            return ProductOverview.passthrough().parse(res);
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get product:', e.message);
                throw e;
            }
            return null;
        }
    });

    getStoreProduct = new Action(async ({ storeGuid, itemId }: { storeGuid: string; itemId: string }) => {
        try {
            if (!itemId) return null;
            const product = await endpoint('storeManager.getStoreProductById', { storeGuid, itemId }).get();
            if (!product) return undefined;
            return StoreProduct.parse(product);
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to get product:', e.message);
                throw e;
            }
            return null;
        }
    });

    async getGlobalProductHistory(itemId: string) {
        try {
            return endpoint('storeManager.getGlobalProductHistory', { itemId }).get({
                transform: (history: any) => history.map((data: any) => this.transformIncomingHistory(data, 'global')),
            });
        } catch (e) {
            console.error(e);
        }
    }

    async getStoreProductHistory(storeGuid: string, itemId: string) {
        try {
            return endpoint('storeManager.getStoreProductHistory', { storeGuid, itemId }).get({
                transform: (history: any) => history.map((data: any) => this.transformIncomingHistory(data, 'store')),
            });
        } catch (e: any) {
            console.error(e);
        }
    }

    async updateProduct(
        itemId: string,
        name: string,
        brand: string,
        contents: any,
        unitOfMeasure: any,
        isTooLight: boolean,
        isTooHeavy: boolean,
    ) {
        try {
            const product = endpoint('storeManager.updateProduct').post({
                itemId,
                name,
                brand,
                contents,
                unitOfMeasure,
                isTooLight: isTooLight ? isTooLight : false,
                isTooHeavy: isTooHeavy ? isTooHeavy : false,
            });
            return product;
        } catch (e: any) {
            if (e.response?.status !== 404) {
                console.error('Failed to update product:', e.message);
                throw e;
            }
            return null;
        }
    }
}

export default service(ProductBrowserService);
