import { ParsedDate } from '@whiz-cart/node-shared/models/parsed/parsedDate';
import { z } from 'zod';

export enum UserType {
    Global = 0,
    Store = 1,
    Tenant = 2,
}

const ScopeType = z.literal('global').or(z.literal('store')).or(z.literal('tenant'));
export type ScopeType = z.infer<typeof ScopeType>;

export const ScopeRoles = z.object({
    scopeType: ScopeType,
    scope: z.string().nullable(),
    roles: z.string().array().nullish(),
});
export type ScopeRoles = z.infer<typeof ScopeRoles>;

export const User = z.object({
    accountGuid: z.string(),
    userName: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    lastActiveOn: ParsedDate.nullish(),
    lastActiveIn: z.string().nullish(),
    accountType: z.nativeEnum(UserType).nullish(),
    scopeRoles: z.record(ScopeRoles).nullish(),
});
export type User = z.infer<typeof User>;
