// @ts-nocheck
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
import { serviceWorkerRegistration } from '../registerWorkers';
import store from '../store';

export default (async () => {
    const config = await store.awaitState('config.firebase');
    firebase.initializeApp(config);

    // Deprecated, but without deleteToken does not work. Bad Google 🙄
    firebase.messaging().useServiceWorker(await serviceWorkerRegistration);

    return firebase;
})();

if (import.meta.hot) {
    import.meta.hot.dispose(() => {
        firebase.app().delete();
    });
    import.meta.hot.accept();
}
