export default {
    scanner: {
        cooldown: 1000,
        commitKeys: ['Enter'],
        ignoreKeys: ['Unidentified', 'Shift'],
    },
    titleBarVisibleActions: 2,
    endpoints: {
        storeManager: {
            login: 'api/v1/account/login',
            validate: 'api/v1/account/validate',
            refresh: 'api/v1/account/refresh',
            changePassword: 'api/v1/account/changePassword',
            getClaims: 'api/v1/account/claims',
            getRoles: 'api/v1/account/roles',
            stores: 'api/v1/account/stores',
            cartTokens: 'api/v1/account/cartTokens',
            getStoreMetadata: 'api/v1/Store/get/{storeGuid}',
            saveStoreMetadata: 'api/v1/Store/create',
            updateStoreMetadata: 'api/v1/Store/update/{storeGuid}',
            listAccounts: 'api/v1/accountManager/getAccounts/{skip}/{limit}',
            getAccount: 'api/v1/accountManager/{accountGuid}',
            createAccount: 'api/v1/accountManager/create',
            updateAccount: 'api/v1/accountManager/{accountGuid}',
            updateAccountGlobalRoles: 'api/v1/accountManager/updateGlobalRoles/{accountGuid}',
            updateAccountStoreRoles: 'api/v1/accountManager/updateStoreRoles/{storeGuid}/{accountGuid}',
            updateAccountTenantRoles: 'api/v1/accountManager/updateTenantRoles/{tenant}/{accountGuid}',
            deleteAccount: 'api/v1/accountManager/{accountGuid}',
            resetPassword: 'api/v1/accountManager/resetPassword',
            ping: 'api/v1/accountManager/ping',
            setPasswordWithEmailToken: 'api/v1/Account/setPasswordWithEmailToken',
            forgotPassword: 'api/v1/Account/forgotPassword',
            getSubscriptions: 'api/v1/webPush/{storeGuid}/list/',
            unsubscribe: 'api/v1/webPush/{storeGuid}/unsubscribe/{topic}',
            navigationNotFoundProducts: 'api/v2/product/{storeGuid}/navigationNotFoundProducts',
            getProductList: 'api/v5/product/{storeGuid}/getList',
            getProductListUnlabelled: 'api/v1/labels/unlabeled/{storeGuid}/{cacheKey}',
            getCommodityGroups: 'api/v1/commoditygroup/{storeGuid}',
            getProductDetails: 'api/v3/product/{storeGuid}/getOverview/{itemId}',
            getStoreProductById: 'api/v4/product/{storeGuid}/getByItemId/{itemId}',
            updateStoreProduct: 'api/v2/product/storeModel/{storeGuid}/update',
            updateProduct: 'api/v2/product/globalModel/update',
            getGlobalProductHistory: 'api/v2/product/globalModel/history/{itemId}',
            getStoreProductHistory: 'api/v2/product/storeModel/{storeGuid}/history/{itemId}',

            getProductByBarcode: 'api/v2/product/{storeGuid}/byBarcode/{barcode}',
            getUnknownProductByBarcode: 'api/v1/product/{storeGuid}/unknown/byBarcode/{barcode}',
            getMHDList: 'api/v1/productExpiration/{storeGuid}/getList',
            updateMDH: 'api/v1/productExpiration/{storeGuid}/update',
            signalR: 'hubs/storeManager?storeGuid={storeGuid}',
            signalRSessionMonitor: 'hubs/sessionMonitor?storeGuid={storeGuid}&sessionGuid={sessionGuid}',
            getConfigValues: 'api/v1/config/{storeGuid}',
            getGroupConfigValues: 'api/v1/config/{storeGuid}/{groupName}',
            updateConfigValue: 'api/v1/config/{storeGuid}/{groupName}',
            productImport: 'api/v2/product/{storeGuid}/import',
            uploadProductImage: 'api/v1/product/{storeGuid}/upload/{barcode}',
            replaceProductImage: 'api/v1/product/replaceProductImage?itemId={itemId}',
            restoreProductImage: 'api/v1/product/restoreProductImage?itemId={itemId}',
            uploadUnknownProduct: 'api/v1/product/{storeGuid}/unknown/update/{barcode}',
            getActiveDevices: 'api/v1/session/{storeGuid}/activeDevices/',
            getNoBarcodeProducts: 'api/v2/product/{storeGuid}/noBarcode/{topic}',
            addToNobarcodeList: 'api/v2/product/{storeGuid}/noBarcode/{topic}/{itemId}',
            deleteNoBarcode: 'api/v2/product/{storeGuid}/noBarcode/{topic}/{itemId}',
            searchProducts: 'api/v2/product/{storeGuid}/searchByItemIdOrGtin/{query}/100',
            setDisableFlag: 'api/v2/product/{storeGuid}/setDisableFlag',
            getPaymentHistory: 'api/v1/Session/{storeGuid}/paidSessions/{skip}/{limit}',
            getCoupons: 'api/v1/CouponActionManager/{storeGuid}/getAll/{skip}/{limit}',
            updateCoupon: 'api/v3/CouponActionManager/addOrUpdate',
            deleteCoupon: 'api/v1/CouponActionManager/{storeGuid}/delete/{couponActionGuid}',
            closeTickets: 'api/v1/ticket/{storeGuid}/close/',
            assignTickets: 'api/v1/ticket/{storeGuid}/assign/',
            unassignTickets: 'api/v1/ticket/{storeGuid}/unassign/',
            updateTickets: 'api/v1/ticket/{storeGuid}/update/',
            pushSubscribe: 'api/v1/webPush/{storeGuid}/subscribe',
            pushSend: 'api/v1/webPush/{storeGuid}/send/{topic}',
            pushTrackReceived: 'api/v1/TicketNotificationTrack/trackReceived',
            pushTrackClick: 'api/v1/TicketNotificationTrack/trackClick',
            getSessionBasket: 'api/v1/session/{storeGuid}/{sessionGuid}',
            getSessionHistory: 'api/v1/session/{storeGuid}/history/{sessionGuid}',
            getSessionsAmount: 'api/v1/Session/{storeGuid}/completedSessionsPerDay/{from}/{to}',
            bookmark: 'api/v1/session/{storeGuid}/suspicious/{sessionGuid}',
            downloadSessionVideo: 'api/v1/surveillance/downloadSessionVideo/{storeGuid}/{sessionGuid}',
            availableSurveillanceSessions: 'api/v1/surveillance/availableSessions',
            getSurveillanceTrackingDocument: 'api/v1/surveillance/getSurveillanceTrackingDocument/{storeGuid}/{sessionGuid}',
            requestSessionVideo: 'api/v1/surveillance/requestSessionVideo/{storeGuid}',
            tara: 'api/v2/session/{storeGuid}/tara',
            getCustomer: 'api/v1/customer/{guidOrHash}',
            getCustomerByGuidOrHash: 'api/v1/customer/search?searchString={searchString}',
            getAvailableFlags: 'api/v1/customer/availableFlags',
            setFlags: 'api/v1/customer/setFlags',
            getDetailedCustomerSessionTracking: 'api/v1/customer/guid/{customerGuid}/tracking/detailed',
            getBaseCustomerSessionTracking: 'api/v1/customer/guid/{customerGuid}/tracking',
            setCustomerTag: 'api/v1/customer/{guidOrHash}/tags/{tag}',
            setCustomerRisk: 'api/v1/customer/{guidOrHash}/risk/',
            deleteCustomerTag: 'api/v1/customer/{guidOrHash}/risk/',
            restartNavigation: 'api/v1/cart/{storeGuid}/{targetGuid}/command',
            getCarts: 'api/v1/cart/getList/{storeGuid}',
            updateCartState: 'api/v1/cart/update/',
            getCartState: 'api/v1/Cart/changes/{cartGuid}',
            cartSetupInit: 'api/v1/CartSetup/{storeGuid}/initProcess?storeGuid={storeGuid}',
            getCodeForSetupService: 'api/v1/cartSetup/generateCodeForSetupService/{storeGuid}',
            navigationPrioritize: 'api/v1/navigation/{storeGuid}/{cartGuid}/prioritize/{priority}',
            getCartLocation: 'api/v1/navigation/{storeGuid}/{cartGuid}/location',
            deleteCart: 'api/v1/cart/delete/{cartGuid}',

            saveShelf: 'api/v1/shelfs/{storeGuid}',
            getShelves: 'api/v1/shelfs/{storeGuid}',
            deleteShelf: 'api/v1/shelfs/{storeGuid}/{shelfGuid}',
            restoreShelf: 'api/v1/Shelfs/{storeGuid}/restoreShelfGuid/{shelfGuid}',
            uploadShelfCsv: 'api/v1/Shelfs/uploadCsv/{storeGuid}/{systemId}',
            setPositionTestShelf: 'api/v1/product/addPosition',
            removePositionTestShelf: 'api/v1/product/removePosition',

            listCartAndStoreDefects: 'api/v1/cartTicket/getList',
            listCartDefects: 'api/v1/cartTicket/getList/{storeGuid}',
            createCartDefect: 'api/v1/cartTicket/create',
            cancelCartDefect: 'api/v1/cartTicket/cancel',
            getCartGpsLocations: 'api/v1/Cart/{storeGuid}/{cartGuid}/locations',
            getGlobalCarts: 'api/v1/cart/globalList',
            getGlobalCartDetailsByFramenumber: 'api/v1/cart/highDetail',
            getGlobalCartDetailsByGuid: 'api/v1/cart/highDetail',

            releaseNotesList: 'api/v1/releasenotes/list/{skip}/{limit}',
            releaseNotesCreate: 'api/v1/releasenotes/create',
            releaseNotesGet: 'api/v1/releasenotes/get/{releaseNotesGuid}',
            releaseNotesUpdate: 'api/v1/releasenotes/update/{releaseNotesGuid}',
            releaseNotesPublish: 'api/v1/releasenotes/publish/{releaseNotesGuid}',
            releaseNotesUnpublish: 'api/v1/releasenotes/unpublish/{releaseNotesGuid}',
            releaseNotesDelete: 'api/v1/releasenotes/delete/{releaseNotesGuid}',
            releaseNotesUpload: 'api/v1/releasenotes/upload',
            getStoreConfig: 'api/v1/Config/{storeGuid}/storeGeneral',
            defaultPackage: 'api/v1/Config/deployment/defaultPackage/{storeGuid}',
            defaultGlobalPackage: 'api/v1/Config/deployment/defaultPackage',

            listStoreOverlays: 'api/v1/CommodityGroup/{storeGuid}/areas',
            getSessionByReceiptNumber: 'api/v1/session/{storeGuid}/receipt/{receiptNumber}',

            reportGetToken: 'api/v1/reports/getToken',
            uploadMap: 'api/v1/map/{storeGuid}/upload',

            listLevels: 'api/v2/map/{storeGuid}/levels',
            updateLevel: 'api/v2/map/{storeGuid}/level',
            deleteLevel: 'api/v2/map/{storeGuid}/level/{systemId}',
            uploadLevelMap: 'api/v2/map/{storeGuid}/{systemId}/upload',
            updateArea: 'api/v2/map/{storeGuid}/{systemId}/area',
            updatePoi: 'api/v2/map/{storeGuid}/{systemId}/poi',
            updatePortal: 'api/v2/map/{storeGuid}/{systemId}/portal',
            getTiles: 'api/v1/storetiles/aggregated/{storeGuid}/systemId/{systemId}',
            updateTiles: 'api/v1/storetiles/updateManualTiles/{storeGuid}',
            navigationSettings: 'api/v2/map/{storeGuid}/settings',

            addProspect: 'api/v1/prospect/add',
            listProspects: 'api/v1/prospect/list',
            getProspectDetails: 'api/v1/prospect/details/{prospectId}',
            setProspectStatus: 'api/v1/prospect/updateStatus/{prospectId}/{status}',
            undoProspectDelete: 'api/v1/prospect/undoDelete',
            deleteProspect: 'api/v1/prospect/delete',

            listStoreSettings: 'api/v1/storeSettings/list',
            getStoreSettings: 'api/v1/storeSettings/get/{storeGuid}/{key}',
            upsertStoreSettings: 'api/v1/storeSettings/upsert',
            upsertStoreSettingsSingleStore: 'api/v1/storeSettings/upsert/{storeGuid}',
            deleteStoreSettings: 'api/v1/storeSettings/delete',
            diffRandomCheck: 'api/v1/storeSettings/diffRandomCheck',
            diffRandomCheckSingleStore: 'api/v1/storeSettings/diffRandomCheck/{storeGuid}',
            manualCheck: 'api/v1/session/{storeGuid}/manualCheck/{sessionGuid}',

            getProductsFromLabel:
                'api/v5/product/{storeGuid}/getList?active={active}&skip={skip}&limit={limit}&productLabel={productLabel}',
            getProductLabels: 'api/v1/labels',
            getProductRootLabels: 'api/v1/labels/root',
            getExtendedProductLabels: 'api/v1/labels/expand/{labelId}',
            createProductLabel: 'api/v1/labels/create',
            updateProductLabel: 'api/v1/labels/update',
            deleteProductLabel: 'api/v1/labels/{labelId}',
            assignManyProductLabels: 'api/v1/labels/assignMany',
            unassignSingleLabel: 'api/v1/labels/unassignSingle/{labelId}',
            excludeSingleLabel: 'api/v1/labels/excludeSingle/{labelId}',
            removeExcludeSingleLabel: 'api/v1/labels/removeExcludeSingle/{labelId}',

            getRwwsCommodityGroups: 'api/v1/commoditygroup/rwws',

            listCartGenerations: 'api/v1/cartGenerations/list',
            upsertCartGenerations: 'api/v1/cartGenerations/update',
            deleteCartGenerations: 'api/v1/cartGenerations/delete/{productNumber}',

            brandingList: 'api/v1/branding/list',
            brandingGet: 'api/v1/branding/{brandId}',
            brandingUpdate: 'api/v1/branding/update/{id}',
            brandingRename: 'api/v1/branding/rename',
            brandingDelete: 'api/v1/branding/delete/{id}',
            brandingUpload: 'api/v1/branding/upload',

            uploadEasyDeals: 'api/v1/digitalCoupons/uploadXls',
            getEasyDeals: 'api/v2/digitalCoupons/list',
            deleteEasyDeal: 'api/v1/digitalCoupons/delete/{id}',

            getProductByItemId: 'api/v4/product/{storeGuid}/getByItemId/{itemId}',
            getProductByGtin: 'api/v4/product/{storeGuid}/getByGtin/{gtin}',

            upsertVoucherPromotion: 'api/v2/promotion/voucherPromotion/addOrUpdate',
            getVoucherPromotions: 'api/v2/promotion/voucherPromotion/list/{storeGuid}',
            deleteVoucherPromotion: 'api/v2/promotion/voucherPromotion/{id}',

            getResourcePlanning: 'api/v1/resourceplanning/get/{storeGuid}',
            getAllResourcePlanning: 'api/v1/resourceplanning/getAll',
            getAllResourcePlanningType: 'api/v1/resourceplanning/getAll/{type}',
            getResourcePlanningType: 'api/v1/resourceplanning/get/{storeGuid}/{type}',
            updateResourcePlanning: 'api/v1/resourceplanning/addOrUpdate',
            deleteResourcePlanning: 'api/v1/resourceplanning/{id}',
            restoreResourcePlanning: 'api/v1/resourceplanning/restore/{id}',

            getResourceProperties: 'api/v1/resourceproperties',
            updateResourceProperty: 'api/v1/resourceproperties/addOrUpdate',
            deleteResourceProperty: 'api/v1/resourceproperties/{id}',
            restoreResourceProperty: 'api/v1/resourceproperties/restore/{id}',

            getStoreAssets: 'api/v1/assets/{storeGuid}/list',
            toggleChargingController: 'api/v1/assets/chargingController/{assetId}/toggle',
            getAssetMetrics: 'api/v1/assets/{storeGuid}/metrics/{assetId}/{metric}',

            getAllLoyalty: 'api/v1/recurringCustomerLoyaltyAction',
            getLoyaltyByStore: 'api/v1/recurringCustomerLoyaltyAction/store/{storeGuid}',
            createLoyalty: 'api/v1/recurringCustomerLoyaltyAction/create',
            updateLoyalty: 'api/v1/recurringCustomerLoyaltyAction/update',
            deleteLoyalty: 'api/v1/recurringCustomerLoyaltyAction/{id}',
            uploadLoyaltyImage: 'api/v1/recurringCustomerLoyaltyAction/uploadImage/{id}',

            getSupportInfo: 'api/v1/supportinfo/{storeGuid}',
            getSupportInfoSections: 'api/v1/supportinfo/sections',
            updateSupportInfo: 'api/v1/supportinfo/{storeGuid}',
            uploadSupportInfoImage: 'api/v1/supportinfo/{storeGuid}/upload',
            updateSupportInfoSection: 'api/v1/supportinfo/sections',
            deleteSupportInfoSection: 'api/v1/supportinfo/sections/{id}',

            classifyCode: 'api/v1/barcodeClassification/{storeGuid}/classify/{code}',
            findByCode: 'api/v1/barcodeClassification/{storeGuid}/find/{code}',
            getClassificationDropdownValues: 'api/v1/barcodeClassification/dropdownvalues',

            getSurveys: 'api/v1/surveys/list/{storeGuid}',
            updateSurvey: 'api/v1/surveys/update',
            deleteSurvey: 'api/v1/surveys/{id}',

            uploadDisruptorImage: 'api/v1/disruptorimage/upload',
        },
        cashier: {
            cashierSignalR: 'hubs/cashierTerminal?storeGuid={storeGuid}',
            getPendingRequests: 'api/v1/payment/{storeGuid}/pendingRequests',
            cancelPayment: 'api/v1/payment/{storeGuid}/cancel',
            completePayment: 'api/v1/payment/{storeGuid}/complete',
            verifyPaymentVerificationItem: 'api/v1/payment/{storeGuid}/verify',
            getPayment: 'api/v1/payment/{storeGuid}/{sessionGuid}/{paymentRequestGuid}',
            lock: 'api/v1/payment/{storeGuid}/lock',
            unlock: 'api/v1/payment/{storeGuid}/unlock',
            posProcessingLock: 'api/v1/payment/{storeGuid}/postProcessing/lock',
            posProcessingUnlock: 'api/v1/payment/{storeGuid}/postProcessing/unlock',
            getPaymentBasket: 'api/v1/payment/{storeGuid}/basketItems/{sessionGuid}/{paymentRequestGuid}',
            expressCheckout: 'api/v1/cashier/{storeGuid}/list',
            getSession: 'api/v1/payment/getSession/{storeGuid}?&sessionGuid={sessionGuid}',

            getIntegrityCheckItem: 'api/v1/basketIntegrityCheck/getItem/{storeGuid}',
            getPendingIntegrityCheckRequest: 'api/v1/basketIntegrityCheck/getPendingItems/{storeGuid}',
            cancelIntegrityCheck: 'api/v1/basketIntegrityCheck/cancel/{storeGuid}',
            updateIntegrityCheck: 'api/v1/basketIntegrityCheck/update/{storeGuid}',
            continueFailed: 'api/v1/basketIntegrityCheck/continueFailed/{storeGuid}',
            getProductInformation: 'api/v1/basketIntegrityCheck/getProductInformation/{storeGuid}',
            confirmSiblingProduct: 'api/v1/basketIntegrityCheck/confirmSiblingProduct/{storeGuid}',
        },
        devOps: {
            version: 'v1/version',
            storesWithEnvironmentsFromInflux: 'v1/storesWithEnvironmentsFromInflux',
            cartServiceStatus: 'v1/cartServiceStatus?cartId={cartId}&storeId={storeId}&environment={env}',
            cartServiceStatusCompare: 'v1/cartServiceStatusCompare?cartId={cartId}&storeId={storeId}&environment={env}',
            storeServiceStatus: 'v1/storeServiceStatus?storeId={storeId}&environment={env}',
            storeServiceStatusV2: 'v2/storeServiceStatus?&environment={env}',
            storeServiceStatusCompare: 'v1/storeServiceStatusCompare?storeId={storeId}&environment={env}',
            availableDockerImages: 'v1/availableDockerImages',
            latestDockerImages: 'v1/latestDockerImages',
            availableAptV2Packages: 'v1/availableAptV2Packages',
            latestAptV2Packages: 'v1/latestAptV2Packages',
            cartDebianPackageStatus: 'v1/cartDebianPackageStatus?cartId={cartId}&storeId={storeId}&environment={env}',
            cartDebianPackageStatusCompare: 'v1/cartDebianPackageStatusCompare?cartId={cartId}&storeId={storeId}&environment={env}',
            cartList: 'v1/cartList?storeId={storeId}&checkLastDays={checkLastDays}&environment={env}',
            outdatedCarts: 'v1/outdatedCarts?storeId={storeId}&checkLastDays={checkLastDays}&environment={environment}',
            adoListReposStatus: 'v1/adoListReposStatus',
            promotedVersion: 'v1/promotedVersion',
            logIssue: 'v1/logIssue',
        },
        azureMonitoring: {
            version: 'v1/version',
            cartDeploymentServiceStatus: 'v1/cartDeploymentServiceStatus',
            elasticSearchQuery: 'v1/elasticSearch/query?index={index}',
        },
        globaldeployment: {
            version: 'v1/version',
            getDeploymentPackageTemplate: 'v1/getDeploymentPackageTemplate?templateGuid={templateGuid}',
            listDeploymentPackageTemplates: 'v1/listDeploymentPackageTemplates',
            downloadDeploymentPackageTemplate: 'v1/downloadDeploymentPackageTemplateGet?templateGuid={templateGuid}',
            createDeploymentPackage: 'v1/createDeploymentPackage',
            getDeploymentPackage: 'v1/getDeploymentPackage?packageGuid={packageGuid}',
            getMultipleDeploymentPackageDetails: 'v1/getMultipleDeploymentPackageDetails',
            listDeploymentPackages: 'v1/listDeploymentPackages',
            downloadDeploymentPackage: 'v1/downloadDeploymentGet?packageGuid={packageGuid}',
            createDeployment: 'v1/createDeployment?packageGuid={packageGuid}&cartId={cartId}&storeId={storeId}&environment={environment}',
            getDeployment: 'v1/getDeployment?deploymentGuid={deploymentGuid}',
            listDeployments: 'v1/listDeployments',
            downloadDeployment: 'v1/downloadDeploymentGet?deploymentGuid={deploymentGuid}',
            assignEnvironmentToCart: 'v1/assignEnvironmentToCart?cartGuid={cartGuid}&environment={environment}',
        },
        deployment: {
            version: 'v1/version',
            listCarts: 'v1/listCarts',
            requestRedeployCarts: 'v1/requestRedeployCarts',
            requestRedeployCartsV2: 'v2/requestRedeployCarts',
            abortRedeployCarts: 'v1/abortRedeployCarts',
            abortRedeployCartsV2: 'v2/abortRedeployCarts',
            resetCarts: 'v1/resetCarts',
            resyncCartsToStore: 'v1/resyncCartsToStore?storeGuid={storeGuid}',
            storeConnections: 'v1/storeConnections',
            lastSeenCartsSync: 'v1/lastSeenCartsSync?storeGuid={storeGuid}',
            lastSeenCartsCache: 'v1/lastSeenCartsCache?storeGuid={storeGuid}',
            lastSeenCartsInSetupSync: 'v1/lastSeenCartsInSetupSync?storeGuid={storeGuid}',
            lastSeenCartsInSetupCache: 'v1/lastSeenCartsInSetupCache?storeGuid={storeGuid}',
            assignDeploymentAndScheduleToCarts: 'v1/assignDeploymentAndScheduleToCarts',
            assignDeploymentAndScheduleToCartsV2: 'v2/assignDeploymentAndScheduleToCarts',
            assignScheduleToCarts: 'v1/assignScheduleToCarts',
            assignScheduleToCartsV2: 'v2/assignScheduleToCarts',
            assignDeployment: 'v1/assignDeploymentToCart',
            scheduleDeployment: 'v1/scheduleDeploymentSteps',
            redeployCart: 'v1/redeployCart?cartGuid={cartGuid}',
            getDeploymentOutputByDeploymentGuid: 'v1/getDeploymentOutputByDeploymentGuid?deploymentGuid={deploymentGuid}',
            latestDeploymentErrors: 'v1/latestDeploymentErrors',
            cartUpdatesWs: 'v1/ws/cartUpdates',
            deploymentUpdatesWs: 'v1/ws/deploymentUpdates',
            setupCart: 'v1/setupCart',
            cartReplacements: 'v1/cartReplacements',
            deploymentGroups: 'v1/deploymentGroups',
            scheduledCartSetupList: 'v1/scheduledCartSetups',
            scheduledCartSetupUpdate: 'v1/scheduledCartSetups',
            scheduledCartSetupDelete: 'v1/scheduledCartSetups',
            createAndDownloadDeployment:
                'v1/debug/createAndDownloadDeployment?storeGuid={storeGuid}&cartGuid={cartGuid}&packageGuid={packageGuid}&jwt={jwt}',
        },
        config: {
            version: 'api/version',
            reset: 'api/v1/config/resetToParent',
            get: 'api/v1/config',
            cart: 'api/v1/config/cart/{cartGuid}',
            overview: 'api/v2/config/overview',
            dimensions: 'api/v1/config/dimensions',
            addOrUpdateMany: 'api/v1/config/addOrUpdateMany',
            compareCartDeploymentConfig: 'api/v1/deploy/compare/store/{storeGuid}/ref/{cartGuid}',
            mergedDeploymentConfigCart: 'api/v1/deploy/store/{storeGuid}/cart/{cartGuid}',
            mergedDeploymentConfigStore: 'api/v1/deploy/store/{storeGuid}/',
        },
        mobileBackend: {
            version: 'api/version',
        },
        search: {
            searchProducts: 'v1/product/search',
        },
        product: {
            version: 'api/version',
            sources: 'api/configuration/sources',
        },
        storeAdmin: {
            version: 'api/version',
            listStores: 'api/v3/list/{skip}/{count}',
            listAllStores: 'api/v3/stores/info',
            getStore: 'api/v2/store/{storeGuid}',
            createStore: 'api/v2/create',
            runMilestone: 'api/v2/run/{storeGuid}/{milestoneId}',
            runAllMilestones: 'api/v2/runAll/{storeGuid}',
            setStoreStatus: 'api/v2/setStoreStatus/{storeGuid}/{status}',
            getStoreMasterData: 'api/v1/storedata/list/{type}',
            addOrupdateStoreMasterData: 'api/v1/storedata/addorupdate',
            deleteStoreMasterData: 'api/v1/storedata/delete/{id}',
            searchImportStores: 'api/v1/edeka/search',
            importStore: 'api/v1/edeka/create/{gln}',
        },
        dsi: {
            getSessions: 'sessions',
            getSessionById: 'sessions/{sessionGuid}',
            lockSession: 'analyze/{sessionGuid}/lock',
            lockSessionForce: 'analyze/{sessionGuid}/lock/force',
            unlockSession: 'analyze/{sessionGuid}/lock',
            unlockSessionForce: 'analyze/{sessionGuid}/lock/force',
            updateIssue: 'analyze/{sessionGuid}/issue',
            deleteIssue: 'analyze/{sessionGuid}/issue/{issueGuid}',
            finishSession: 'analyze/{sessionGuid}/finish',
            updateImageLabel: 'imageLabelling',
            getImageLabels: 'imageLabelling/{sessionGuid}',
            deleteImageLabel: 'imageLabelling/{sessionGuid}/{correlationGuid}/{url}',
            ws: '',
            getSuspiciousSessions: 'inspections/suspicious-sessions',
            getSuspiciousSessionsToConfirm: 'inspections/suspicious-sessions/confirm',
            setSuspiciousSessionInspection: 'inspections/suspicious-sessions/{sessionGuid}',
            setSuspiciousSessionInspections: 'inspections/suspicious-sessions',
            setSuspiciousSessionInspectionConfirmation: 'inspections/suspicious-sessions/confirm/{sessionGuid}',
        },
        dataSync: {
            downstreamClientDatabaseStatus: 'downstreamClientDatabaseStatus?downstreamClientGuids={downstreamClientGuid}',
            downstreamClientSyncs:
                'downstreamClientSyncs?clientGuid={clientGuid}&connectionGuid={connectionGuid}&requestGuid={requestGuid}&operationGuid={operationGuid}&requestor={requestor}&requestorReason={requestorReason}&filterForwarded={filterForwarded}',
            storedMonitoringData: 'storedMonitoringData',
            aggregatedClientDatabaseStatus: 'aggregatedClientDatabaseStatus?hideClientDetails={hideClientDetails}',
            resync: 'resync?ruleName={ruleName}&clientGuid={clientGuid}&requestor=storeManagerUi&requestorReason={requestorReason}',
        },
        buildMonitor: {
            socket: '',
        },
        navigationService: {
            getPpath: 'v1/path',
        },
        masterDataService: {
            getAll: 'barcodeclassifications2/all/{skip}/{limit}',
            getHierarchy: 'barcodeclassifications2/hierarchy',
            getById: 'barcodeclassifications2/byId/{id}',
            update: 'barcodeclassifications2',
            delete: 'barcodeclassifications2/{id}',

            getAllPromotionSettings: 'promotionsettings/all/{skip}/{limit}',
            updatePromotionSettings: 'promotionsettings',
            deletePromotionSettings: 'promotionsettings/{id}',
            getByStoreGuidPromotionSettings: 'promotionsettings/merged/{storeGuid}/{skip}/{limit}',

            getDisruptorImages: 'disruptorimages/hierarchy',
            createDisruptorImage: 'disruptorimages',
            deleteDisruptorImage: 'disruptorimages/{guid}',
            updateDisruptorImage: 'disruptorimages',

            getDisruptorConfigs: 'disruptorsettings/hierarchy',
            createDisruptorConfig: 'disruptorsettings',
            deleteDisruptorConfig: 'disruptorsettings/{guid}',
            updateDisruptorConfig: 'disruptorsettings',
        },
    },
    showDeferred: true,
    disableDSI: true,
    updateInterval: 3600000,
    cartLoginUpdateInterval: 900000,
    preliminaryLoadingTimeout: 3000,
    locationsStaleAfter: 60000,
    dsi: {
        numberOfCachedEventsImages: 3,
    },
    logAnalyzer: {
        index: 'cart-logs-*',
    },
} as const;
